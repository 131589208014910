<template>
  <v-container>
    <v-card class="pa-2">
      <div v-if="searching">
        <p class="text-center mt-5">
          <v-icon>fas fa-atom fa-spin</v-icon> Carregando tarefas ...
        </p>
      </div>
      <div class="pa-2">
        <template v-if="searchResult && searchResult.data && searchResult.data">
          <v-card>
            <v-card-title>
              Tarefas
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchResults"
                append-icon="fas fa-search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-btn
                :disabled="searching"
                color="success"
                class="ml-5"
                @click="
                  openItem({
                    name: null,
                    url: null,
                    limit: null,
                    wait_minutes: null,
                    active: true
                  })
                "
                ><v-icon>fas fa-plus</v-icon></v-btn
              >
            </v-card-title>
            <v-data-table
              style="cursor: pointer;"
              :headers="headers"
              :items="searchResult.data"
              :sort-by="['ProductPrice.perc_desc']"
              :sort-desc="[true]"
              item-key="amz"
              class="elevation-1"
              :search="searchResults"
              @click:row="openItem"
            >
              <template v-slot:item.agent_id="{ item }">
                {{getAgentName(item.agent_id)}}
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn icon @click.stop="deleteTask(item)" :disabled="searching"
                  ><v-icon color="red">fas fa-times</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.url="{ item }">
                {{ item.url }}
              </template>
            </v-data-table>
          </v-card>
        </template>
      </div>
      <v-dialog v-model="dialog" width="700">
        <v-card v-if="selectedProd">
          <v-card-title class="headline" primary-title>
            Editar: {{ selectedProd.name }}
          </v-card-title>
          <v-card-text class="text-center">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :disabled="searching"
                  label="Nome"
                  v-model="selectedProd.name"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :disabled="searching"
                  type="number"
                  max="500"
                  label="Quantidade de resutlados (max 500)"
                  v-model="selectedProd.limit"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  :disabled="searching"
                  label="URL"
                  v-model="selectedProd.url"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  :disabled="searching"
                  type="number"
                  label="Intervalo (minutos)"
                  v-model="selectedProd.wait_minutes"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-select
                  v-model="selectedProd.store_id"
                  :items="stores"
                  item-value="id"
                  item-text="name"
                  label="Loja"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="selectedProd.agent_id"
                  :items="agents"
                  item-value="id"
                  item-text="name"
                  label="Agente"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-switch
                  :disabled="searching"
                  v-model="selectedProd.active"
                  :label="`Ativo: ${selectedProd.active.toString()}`"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="searching" color="success" @click="editOrSave()"
              ><v-icon>fas fa-save</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
        @click="fetchSearch()"
        class="mr-3"
        style="margin-bottom: 50px"
        :disabled="searching"
        absolute
        fab
        bottom
        fixed
        right
        color="warning"
      >
        <v-icon>fas fa-sync</v-icon>
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'amz',
  components: {},
  data() {
    return {
      selectedProd: null,
      dialog: false,
      search: null,
      ASIN: null,
      limit: 10,
      prime: false,
      agents: [],
      searchResults: null,
      searchResult: null,
      searching: false,
      stores: [],
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Qtd. de resultados', value: 'limit' },
        { text: 'Loja', value: 'store.name' },
        { text: 'ùltima sincronização', value: 'last_sync' },
        { text: 'Ativo', value: 'active' },
        { text: 'Intervalo (minutos)', value: 'wait_minutes' },
        { text: 'Agente', value: 'agent_id' },
        { text: 'Remover', value: 'action' },
        { text: 'url', value: 'url', width: '100px' }
      ]
    }
  },
  mounted() {
    this.fetchSearch()
    this.fetchStores()
    this.fetchAgents()
  },
  created() {},
  methods: {
    deleteTask(task) {
      var vm = this
      if (confirm('Deseja realmente excluir a tarefa?')) {
        vm.searching = true
        vm.$API
          .Request('delete', `tasks/delete/${task.id}`)
          .then(() => {
            vm.$snotify.success('Tarefa removida com sucesso')
            console.log('Tarefa removida com sucesso')
            for (let ixsr = 0; ixsr < vm.searchResult.data.length; ixsr++) {
              const _task = vm.searchResult.data[ixsr]
              if (_task.id === task.id) {
                vm.searchResult.data.splice(ixsr, 1)
                break
              }
            }
            vm.searchResult.data.push({})
            vm.searchResult.data.pop()
            vm.searching = false
          })
          .catch(error => {
            vm.searching = false
            console.debug(error)
            vm.$snotify.error(error.message)
          })
      }
    },
    copyTestingCode(_elementID) {
      var vm = this
      let testingCodeToCopy = document.querySelector(`#${_elementID}`)
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        if (document.execCommand('copy')) {
          vm.$snotify.success(`Texto copiado para a área de transferência`)
        } else {
          throw new Error(
            'Não foi possível copiar o texto para área de transferência. Copie manualmente'
          )
        }
      } catch (_err) {
        vm.$snotify.error(`Oops... ${_err.message}`)
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    fetchSearch() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `tasks/list`)
        .then(result => {
          console.debug(result)
          vm.searchResult = result
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    fetchStores() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `stores/list`)
        .then(result => {
          if (result && result.data) {
            vm.stores = result.data
          }
        })
        .catch(error => {
          console.debug(error)
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    fetchAgents() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `agents/list`)
        .then(result => {
          if (result && result.data) {
            vm.agents.push({
              id: null,
              name: 'NENHUM'
            })
            vm.agents.push(...result.data)
            console.debug(vm.agents)
          }
        })
        .catch(error => {
          console.debug(error)
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    editOrSave() {
      if (this.selectedProd.id) {
        this.editTask()
      } else {
        this.newTask()
      }
    },
    editTask() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `tasks/edit`, vm.selectedProd)
        .then(() => {
          vm.$snotify.success('Tarefa atualizada')
          vm.dialog = false
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    newTask() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `tasks/new`, vm.selectedProd)
        .then(() => {
          vm.$snotify.success('Tarefa criada com sucess')
          vm.dialog = false
          vm.fetchSearch()
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    openItem(_i) {
      var vm = this
      vm.selectedProd = _i
      vm.dialog = true
    },
    getAgentName(agent_id) {
      var vm = this
      for (let ixagnt in vm.agents) {
        const _a = vm.agents[ixagnt]
        if (_a.id == agent_id) {
          return _a.name
        }
      }
      return 'NENHUM*'
    }
  }
}
</script>
